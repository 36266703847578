@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.md5-id {
    letter-spacing: 0.5rem;
    text-decoration: underline;
}

.md5-container {
    font-size: 1.35rem;
    font-weight: 700;
    font-family: "Roboto Mono", "monospace"
}
body {
  box-sizing: border-box;
  display: flex;
  background-color: #1c1c1d;
}

.App {
  width: 100vw;
}

.button {
  display: inline-block;
  margin: 10px;
  padding: 5px 15px;
  background-color: #000;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}

.image {
	display: inline-flex;
	width: auto;
}

.inner-image {
	flex-direction: column;
}

.image .row {
	flex-direction: row;
}

.image .row .pixel {
	margin: 0px;
	display: inline-block;
}

